import './App.css';

function App() {
  const currentPath = window.location.pathname;
  var all = "https://api.greenwayitsolutions.com/media/images/"+currentPath;
  return (
    <div className="container">
         <img src= {all} />
    </div>
  );
}

export default App;